@media (max-height: 399px) {
    #root {
        height: auto;
    };
    #header {
        position: relative;
    };
    #footer {
        padding-bottom: 0;
        border-radius: 0
    };
    #mobile_navigation {
        position: relative;
    };
  }