ol.stylized, ul.stylized {
  margin-top: 0px;
  padding-inline-start: 2.5rem;
  li {
    color: var(--root-text-color);
  }
}
ol.stylized {
  li {
    list-style-type: none;
    counter-increment: list;
    position: relative;
    &:before {
      content: counter(list) ".";
      position: absolute;
      left: -2.75em;
      width: 2em;
      text-align: right;
      color: var(--root-text-color);
      line-height: 1.5;
      font-size: 1rem;
    }
  }
}
ul.stylized {
  li {
    list-style-type: disc;
    position: relative;
  }
}
ul.unstylized {
  padding-inline-start: 0;
}